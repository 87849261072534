/* eslint-disable import/prefer-default-export */
import ApiService from './ApiService';

class SenderService {
  static async getQuickResponses() {
    const composedURL = '/api3/fast_reply/';
    const response = ApiService.get(composedURL);
    return response;
  }

  static async getProperties(searchword) {
    const composedURL = `/api3/sender/search/?searchword=${searchword}`;
    const response = ApiService.get(composedURL);
    return response;
  }

  static async getPropertyFiles(propertyId) {
    const composedURL = `/api3/property/${propertyId}/quick/files?file_type=files`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDevelopmentFiles(developmentId) {
    const composedURL = `/api3/development/${developmentId}/quick/files`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getPropertyAndDevelopmentFiles({ propertyId, developmentId }) {
    const composedURLProperty = `/api3/property/${propertyId}/quick/files?file_type=files`;
    const composedURLDevelopment = `/api3/development/${developmentId}/quick/files`;
    const [property, development] = await Promise.all([
      ApiService.get(composedURLProperty),
      ApiService.get(composedURLDevelopment),
    ]);

    const response = { development, property };
    return response;
  }

  static async getPropertiesFilesById(ids) {
    const composedURL = `/api3/property/files?file_type=files&properties=${ids}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getContactReports(contactId) {
    const composedURL = `/api3/owner/${contactId}/properties`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getPropertiesById(listOfPropertiesId) {
    const composedURL = `/api3/sender/property?ids=${listOfPropertiesId}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getPropertiesByHash(listOfPropertiesHash) {
    const composedURL = `/portals/opennavent/api3/posting/properties_card?properties_posting_hash=${listOfPropertiesHash}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDevelopmentsById(listOfDevelopmentsId) {
    const composedURL = `/api3/sender/development?ids=${listOfDevelopmentsId}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getFichaInfoUrl(data) {
    const { id, contactId, isEdited, lang } = data;
    const composedURL = `/api3/property/get_ficha_info_url?properties_id=${id}&contact_id=${contactId}&is_edited=${isEdited}&lang=${lang}`;
    const response = await ApiService.get(composedURL);
    // Se agrega timestamp para evitar cache en OpenGraph
    const timestamp = new Date().getTime();
    response.ficha_info_url = `${response.ficha_info_url}?v=${timestamp}`;
    return response;
  }

  static async trackWhatsappMessage(data) {
    const composedURL = '/api3/timeline/wasap';
    const response = await ApiService.post(composedURL, data);
    return response;
  }

  static async sendEmail(payload) {
    const composedURL = '/api3/contact/send_props_or_devs_to_contacts';
    const response = await ApiService.post(composedURL, payload);
    return response;
  }

  static async validateWhatsapp(number) {
    const composedURL = `/api3/twilio/validate_phone?phone=${number}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async replaceWhatsappMessage(data) {
    const composedURL = `/api3/fast_reply/replace_dynamic_tags/?contact_id=${
      data.contactId
    }&message=${encodeURIComponent(data.message)}`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default SenderService;
