import ApiService from '@/services/ApiService';

class CompanyService {
  static async putUpgradePending() {
    const composedURL = `/api3/company/upgrade_pending`;
    const requestBody = { site_generator: true };
    const response = await ApiService.put(composedURL, requestBody);
    return response;
  }
}

export default CompanyService;
