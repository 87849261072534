/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import InstagramService from '@/services/InstagramService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`InstagramPost/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getPublications = handler(
  'getPublications',
  InstagramService.getPublications
);

export const getInstagramProperties = handler(
  'getInstagramProperties',
  InstagramService.getInstagramProperties
);

export const getInstagramPropertiesCount = handler(
  'getInstagramPropertiesCount',
  InstagramService.getInstagramPropertiesCount
);
