function replaceSubstring(inputString, target, replacement) {
  const regex = new RegExp(target, 'g');
  return inputString.replace(regex, replacement);
}

function replaceEmptyParagraphs(html) {
  return html.replace(/<p>\s*<\/p>/g, '');
}

function removeWhitespaceBetweenTags(html) {
  return html.replace(/>\s*</g, '><');
}

function removeLeadingNewlines(html) {
  return html.replace(/^\s*[\r\n]/gm, '');
}

function simplifyNestedLineBreak(html) {
  return html
    .replace(/<p>\s*<span[^>]*>\s*<br>\s*<\/span>\s*<\/p>/g, '<p><br></p>')
    .replace(/\s*<div[^>]*>\s*<br>\s*<\/div>\s*/g, '<p><br></p>');
}

function replaceLineBreakParagraph(html) {
  return replaceSubstring(html, '<p><br></p>', '<p></p>');
}

function sanitizeHtml(stringHtml) {
  let sanitizedHtml = stringHtml.replaceAll('\\r\\n', ' ');

  sanitizedHtml = replaceEmptyParagraphs(sanitizedHtml);
  sanitizedHtml = removeWhitespaceBetweenTags(sanitizedHtml);
  sanitizedHtml = removeLeadingNewlines(sanitizedHtml);
  sanitizedHtml = simplifyNestedLineBreak(sanitizedHtml);
  sanitizedHtml = replaceLineBreakParagraph(sanitizedHtml);

  return sanitizedHtml;
}

export default sanitizeHtml;
