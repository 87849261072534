import { createSlice } from '@reduxjs/toolkit';
import {
  deleteExtraAttribute,
  getAttributesToSearchResult,
  getAttributesFromPropertiesTypes,
  getExtraAttributesFromPropertyType,
  getPropertiesTypes,
  saveAttributeEdition,
  saveExtraAttribute,
  savePropertyTypeEdition,
  saveSearchResultAttribute,
  getSearchResultAttributes,
  getAllTagsGroup,
  getAllowedTagGroupsByProperty,
  saveTagGroupActivation,
  saveTagGroupDeactivation,
  saveNewTagGroup,
  getRecommendedConfigFromPropertiesTypes,
  saveRecommendedConfig,
  putUpgradePending,
  getUpgradePending,
} from './services';

const initialState = {
  loading: false,
  loadingAttributes: true,
  property_types: [],
  extraAttributes: {
    loading: false,
    availableIndex: 0,
    is_expenditure: [],
    is_measure: [],
  },
  attributes: { loading: false },
  recommendedConfig: { loading: false },
  attributesToSearchResult: {
    loading: false,
    basic_info: [],
    surface_and_measures: [],
  },
  searchResultAttributes: [],
  tagGroupAttributes: { loading: false, groups: [], activeGroups: [] },
  propertyTypeSelected: {},
  showTagGroupModal: { show: false, from: 'inside' },
  showPreview: false,
  showModals: {
    recommendedConfirm: false,
    upgradeSend: false,
    upgradePlanSelection: false,
  },
  upgradeRquestPending: { exist: false, loading: false },
};

export const PropertyConfiguratorSlice = createSlice({
  name: 'propertyConfigurator',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPropertyType: (state, action) => {
      state.propertyTypeSelected = action.payload;
    },
    togglePropertyType: (state, action) => {
      const propertyType = state.property_types.find(
        (type) => type.id === action.payload.id
      );
      if (propertyType) {
        propertyType.active = !propertyType.active;
      }
    },
    toggleAttribute: (state, action) => {
      const { section, attribute, active } = action.payload;
      const attributesInState = state.attributes.attributes[section];
      const attributeFiltered = attributesInState.find(
        (attr) => attr.name === attribute.name
      );
      if (attributeFiltered) {
        attributeFiltered.active = active;
      }
    },
    toogleTagGroup: (state, action) => {
      const { tagGroup, active } = action.payload;
      let newTagGroupEnabled = [];
      if (active) {
        newTagGroupEnabled = [
          ...state.tagGroupAttributes.activeGroups,
          tagGroup,
        ];
      } else {
        newTagGroupEnabled = state.tagGroupAttributes.activeGroups.filter(
          (tag) => tag.id !== tagGroup.id
        );
      }
      state.tagGroupAttributes.activeGroups = newTagGroupEnabled;
    },
    setTagGroupIsPublic: (state, action) => {
      const tagGroup = action.payload;
      const { id, is_public } = tagGroup;
      const isPublicValue = is_public !== undefined ? is_public : true;
      const newTagGroupEnabled = state.tagGroupAttributes.activeGroups.map(
        (tag) => {
          if (tag.id === id) {
            return { ...tag, is_public: isPublicValue };
          }
          return tag;
        }
      );
      state.tagGroupAttributes.activeGroups = newTagGroupEnabled;
    },
    setShowTagGroupModal: (state, action) => {
      state.showTagGroupModal = action.payload;
    },
    setShowPreview: (state, action) => {
      state.showPreview = action.payload;
    },
    setExtraAttributes: (state, action) => {
      const { key, value } = action.payload;
      if (!key) state.extraAttributes = action.payload;
      else state.extraAttributes = { ...state.extraAttributes, [key]: value };
    },
    setExtraAttributeSelection: (state, action) => {
      const { type, attributeSelected, edition } = action.payload;
      const extraAttributes = state.extraAttributes[type];
      const newExtraAttributes = extraAttributes.map((attribute) => {
        if (attribute.index === attributeSelected.index) {
          return { ...attribute, edition };
        }
        return attribute;
      });
      state.extraAttributes = {
        ...state.extraAttributes,
        [type]: newExtraAttributes,
      };
    },
    setSearchResultAttributes: (state, action) => {
      const { attribute, index } = action.payload;
      const newSearchResults = state.searchResultAttributes.map((attr, i) => {
        if (i === index) {
          return attribute;
        }
        return attr;
      });
      state.searchResultAttributes = newSearchResults;
    },
    setShowModals: (state, action) => {
      const { key, value } = action.payload;
      state.showModals = {
        ...state.showModals,
        [key]: value,
      };
      if (key === 'recommendedConfirm' && value) {
        state.showPreview = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder // Get properties types
      .addCase(getPropertiesTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPropertiesTypes.fulfilled, (state, action) => {
        const { property_type } = action.payload;
        state.property_types = property_type;
        state.loading = false;
      });
    builder // Get attributes from property type
      .addCase(getRecommendedConfigFromPropertiesTypes.pending, (state) => {
        state.recommendedConfig = { loading: true };
      })
      .addCase(
        getRecommendedConfigFromPropertiesTypes.fulfilled,
        (state, action) => {
          state.recommendedConfig = {
            loading: false,
            attributes: action.payload,
          };
        }
      );
    builder // Get recommended config from property type
      .addCase(getAttributesFromPropertiesTypes.pending, (state) => {
        state.attributes = { loading: true };
      })
      .addCase(getAttributesFromPropertiesTypes.fulfilled, (state, action) => {
        state.attributes = { loading: false, attributes: action.payload };
      });
    builder // Get attributes to search results
      .addCase(getAttributesToSearchResult.pending, (state) => {
        state.attributesToSearchResult = {
          ...state.attributesToSearchResult,
          loading: true,
        };
      })
      .addCase(getAttributesToSearchResult.fulfilled, (state, action) => {
        state.attributesToSearchResult = {
          ...action.payload,
          loading: false,
        };
      });
    builder // Get search results attributes
      .addCase(getSearchResultAttributes.pending, (state) => {
        state.attributesToSearchResult = {
          ...state.attributesToSearchResult,
          loading: true,
        };
      });
    builder.addCase(getSearchResultAttributes.fulfilled, (state, action) => {
      state.searchResultAttributes = action.payload;
      state.attributesToSearchResult = {
        ...state.attributesToSearchResult,
        loading: false,
      };
    });
    builder // Get all tags group attributes
      .addCase(getAllTagsGroup.pending, (state) => {
        state.tagGroupAttributes = {
          ...state.tagGroupAttributes,
          loading: true,
        };
      });
    builder.addCase(getAllTagsGroup.fulfilled, (state, action) => {
      state.tagGroupAttributes = {
        ...state.tagGroupAttributes,
        groups: action.payload,
        loading: false,
      };
    });
    builder // Get allowed tags groups by property type
      .addCase(getAllowedTagGroupsByProperty.pending, (state) => {
        state.tagGroupAttributes = {
          ...state.tagGroupAttributes,
          loading: true,
        };
      });
    builder.addCase(
      getAllowedTagGroupsByProperty.fulfilled,
      (state, action) => {
        state.tagGroupAttributes = {
          ...state.tagGroupAttributes,
          activeGroups: action.payload,
          loading: false,
        };
      }
    );
    builder // Get extra attribute from property type
      .addCase(getExtraAttributesFromPropertyType.pending, (state) => {
        state.extraAttributes = { loading: true };
      })
      .addCase(
        getExtraAttributesFromPropertyType.fulfilled,
        (state, action) => {
          const attributes = action.payload.length
            ? action.payload.map((attr, index) => ({ ...attr, index }))
            : [];
          const expenditureValidation = (attr) => !attr.is_measure;
          const measureValidation = (attr) => attr.is_measure;

          const is_expenditure = attributes.filter((attr) =>
            expenditureValidation(attr)
          );
          const is_measure = attributes.filter((attr) =>
            measureValidation(attr)
          );
          state.extraAttributes = {
            is_expenditure,
            is_measure,
            loading: false,
            availableIndex: attributes.length,
          };
        }
      );
    builder // Save edition property types
      .addCase(savePropertyTypeEdition.fulfilled, (state) => {
        state.loading = false;
      });
    builder // Save edition tokko attributes
      .addCase(saveAttributeEdition.fulfilled, (state) => {
        state.loadingAttributes = false;
      });
    builder // Save creation/edition extra attributes
      .addCase(saveExtraAttribute.fulfilled, (state) => {
        state.loadingAttributes = false;
      });
    builder // Save search result attributes
      .addCase(saveSearchResultAttribute.fulfilled, (state) => {
        state.loadingAttributes = false;
      });
    builder // Delete extra attributes
      .addCase(deleteExtraAttribute.fulfilled, (state) => {
        state.loadingAttributes = false;
      });
    builder // Activate tag group
      .addCase(saveTagGroupActivation.fulfilled, (state) => {
        state.loadingAttributes = false;
      });
    builder // Deactivate tag group
      .addCase(saveTagGroupDeactivation.fulfilled, (state) => {
        state.loadingAttributes = false;
      });
    builder // Create a new tag group
      .addCase(saveNewTagGroup.fulfilled, (state) => {
        state.tagGroupAttributes = {
          ...state.tagGroupAttributes,
          loading: false,
        };
      });
    builder // Update recommended config
      .addCase(saveRecommendedConfig.pending, (state) => {
        state.attributes = { ...state.attributes, loading: true };
      })
      .addCase(saveRecommendedConfig.fulfilled, (state) => {
        state.attributes = {
          attributes: state.recommendedConfig.attributes,
          loading: false,
        };
      });
    builder // Get upseling request pendings
      .addCase(getUpgradePending.pending, (state) => {
        state.upgradeRquestPending = {
          ...state.upgradeRquestPending,
          loading: true,
        };
      })
      .addCase(getUpgradePending.fulfilled, (state, action) => {
        const pendingRequestExist = action.payload.attributes_per_property_type;
        state.upgradeRquestPending = {
          exist: pendingRequestExist,
          loading: false,
        };
      });
    builder // Put upseling request
      .addCase(putUpgradePending.pending, (state) => {
        state.upgradeRquestPending = {
          ...state.upgradeRquestPending,
          loading: true,
        };
      })
      .addCase(putUpgradePending.fulfilled, (state) => {
        state.upgradeRquestPending = { exist: true, loading: false };
      });
  },
});

export const {
  setLoading,
  setLoadingAttributes,
  setPropertyType,
  togglePropertyType,
  toggleAttribute,
  toogleTagGroup,
  setTagGroupIsPublic,
  setNumericAttribute,
  setShowTagGroupModal,
  setShowPreview,
  setTagGroupAttributes,
  setExtraAttributes,
  setExtraAttributeSelection,
  setSearchResultAttributes,
  setShowModals,
} = PropertyConfiguratorSlice.actions;

export default PropertyConfiguratorSlice.reducer;
