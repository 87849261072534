import { createAsyncThunk } from '@reduxjs/toolkit';
import InstagramService from '@/services/InstagramService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`InstagramPost/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

export const getInstagramEntityData = handler(
  'getInstagramEntityData',
  InstagramService.getEntityData
);

export const postInstagramPublication = handler(
  'postInstagramPublication',
  InstagramService.postPublication
);

export const getIsUserLoggedOnInstagram = handler(
  'getIsUserLoggedOnInstagram',
  InstagramService.getIsUserLogged
);

export const generateInstagramAIDescription = handler(
  'generateInstagramAIDescription',
  InstagramService.generateInstagramAIDescription
);

export const getInstagramAIConfiguration = handler(
  'getInstagramAIConfiguration',
  InstagramService.getInstagramAIConfiguration
);

export const getIfPlanUpgradeWasRequested = handler(
  'getIfPlanUpgradeWasRequested',
  InstagramService.getIfPlanUpgradeWasRequested
);
