import { createSlice } from '@reduxjs/toolkit';
import {
  getInstagramProperties,
  getInstagramPropertiesCount,
  getPublications,
} from './services';

const initialState = {
  properties: {
    loading: false,
    paginator: { page: 1, count: false },
    list: [],
  },
  propertyCounters: {
    loading: false,
    counters: false,
    typeSelected: 1,
  },
};

export const InstagramPublicationsSlice = createSlice({
  name: 'ToasInstagramPublicationst',
  initialState,
  reducers: {
    changeTypeProperty(state, action) {
      state.propertyCounters.typeSelected = action.payload;
      state.properties.paginator.count = false;
    },

    setPage(state, action) {
      state.properties.paginator.page = action.payload;
    },

    deletePublications(state, action) {
      const { postID, propID } = action.payload;
      const { list } = state.properties;
      const findProp = state.properties.list.find((item) => item.id === propID);

      if (findProp.publications_count === 1) {
        state.properties.list = list.filter((item) => item.id !== propID);
        state.propertyCounters.counters.posted_properties -= 1;
        state.propertyCounters.counters.not_published += 1;
      } else {
        state.properties.list = list.map((item) => {
          if (item.id === propID) {
            return {
              ...item,
              publications_count: item.publications_count - 1,
              publications: {
                ...item.publications,
                list: item.publications.list.filter(
                  (subItem) => subItem !== postID
                ),
              },
            };
          }

          return item;
        });
      }
    },
  },
  extraReducers: (builder) => {
    // Get publications
    builder.addCase(getPublications.pending, (state, action) => {
      const { list } = state.properties;
      const { arg } = action.meta;

      state.properties.list = list.map((item) => {
        if (item.id === arg) {
          return {
            ...item,
            publications: { loading: true, list: false },
          };
        }
        return item;
      });
    });
    builder.addCase(getPublications.fulfilled, (state, action) => {
      const { list } = state.properties;
      const { arg } = action.meta;

      state.properties.list = list.map((item) => {
        if (item.id === arg) {
          return {
            ...item,
            publications: { loading: false, list: action.payload.results },
          };
        }
        return item;
      });
    });

    // Get instagram properties
    builder.addCase(getInstagramProperties.pending, (state) => {
      state.properties.loading = true;
    });
    builder.addCase(getInstagramProperties.fulfilled, (state, action) => {
      state.properties.loading = false;

      const { count, next, previous, results } = action.payload;

      state.properties.list = results.map((item) => ({
        ...item,
        publications: { loading: false, list: false },
      }));

      const { page } = state.properties.paginator;

      state.properties.paginator = { page, count, next, previous };
    });

    // Get instagram properties count
    builder.addCase(getInstagramPropertiesCount.pending, (state) => {
      state.propertyCounters.loading = true;
    });
    builder.addCase(getInstagramPropertiesCount.fulfilled, (state, action) => {
      state.propertyCounters.loading = false;

      state.propertyCounters.counters = action.payload;
    });
  },
});

export const { changeTypeProperty, setPage, deletePublications } =
  InstagramPublicationsSlice.actions;

export default InstagramPublicationsSlice.reducer;
