import ApiService from './ApiService';

class QuickDisplayService {
  static async getFichaInfoUrl(data) {
    const composedURL = `/api3/property/get_ficha_info_url?properties_id=${data.id}&is_edited=${data.edited}&for_colleague=${data.colleage}&is_for_edit=${data.isEditable}`;
    const response = await ApiService.get(composedURL);
    // Se agrega timestamp para evitar cache en OpenGraph
    const timestamp = new Date().getTime();
    response.ficha_info_url = `${response.ficha_info_url}?v=${timestamp}`;
    return response;
  }

  static async isPropOrDevStarred(data) {
    const composedURL = `/api3/company/starred?contact=${data.contact}&id=${data.id}&is_development=${data.isDevelopment}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getQuickPropData(id) {
    const composedURL = `/api3/property/${id}/quick`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getQuickDevData(id) {
    const composedURL = `/api3/development/${id}/quick`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getQuickOpenNaventData(id) {
    const composedURL = `/portals/opennavent/api3/posting/${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getSents(id) {
    const composedURL = `/api3/property/${id}/quick/sents`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDevelopmentSents(id) {
    const composedURL = `/api3/development/${id}/quick/sents`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getFiles(id) {
    const composedURL = `/api3/property/${id}/quick/files?file_type=files`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDevelopmentFiles(id) {
    const composedURL = `/api3/development/${id}/quick/files?file_type=files`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async starProperty(data) {
    const composedURL = `/api3/property/star_property?property_id=${
      data.idProp
    }&contacts_ids=${JSON.stringify(data.idsContacts)}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async starDevelopment(data) {
    const composedURL = `/api3/development/${
      data.idProp
    }/star_unstar?contacts_id=${JSON.stringify(data.idsContacts)}`;
    const response = await ApiService.post(composedURL);
    return response;
  }

  static async toggleFavourite(id) {
    const composedURL = `/api3/user/toggle_favourite/${id}`;
    const response = await ApiService.post(composedURL);
    return response;
  }

  static async toggleFavouriteDevelopment(id) {
    const composedURL = `/api3/user/dev_toggle_favourite/${id}`;
    const response = await ApiService.post(composedURL);
    return response;
  }
}

export default QuickDisplayService;
