import { createAsyncThunk } from '@reduxjs/toolkit';
import WebsitesService from '@/services/WebsitesService';
import TemplatesService from '@/services/TemplatesService';
import LocationsService from '@/services/LocationsService';
import PropertiesService from '@/services/PropertiesService';
import FavoritesService from '@/services/FavoritesService';
import CompanyService from '@/services/CompanyService';
import UserService from '@/services/UserService';
import PropertyConfiguratorService from '@/services/PropertyConfiguratorService';

const handler = (name, asyncFunction) =>
  createAsyncThunk(`template-config-data/${name}`, async (arg) => {
    const data = await asyncFunction(arg);
    return data;
  });

const fetchWebsiteDataHandler = async (websiteId) => {
  const response = await WebsitesService.getWebsiteData(websiteId);
  return response;
};

const fetchFavoriteSearchesHandler = async () => {
  const response = await FavoritesService.getFavSearchs();
  const res = response.searches.map((r) => ({
    id: r.id,
    name: r.name,
    search_params: r.search_params,
    value: r.id,
  }));
  return res;
};

const removeAliasWebsiteHandler = async ({ websiteId, aliasId }) => {
  const response = await WebsitesService.removeAliasWebsite(websiteId, aliasId);
  return response;
};

const saveAliasWebsiteHandler = async ({ websiteId, aliasText }) => {
  const response = await WebsitesService.saveAliasWebsite(websiteId, aliasText);
  return { aliasText, objectId: response.object_id };
};

const updateWebsiteHandler = async ({ websiteId, requestBody }) => {
  const response = await WebsitesService.updateWebsite(websiteId, requestBody);
  return response;
};

const uploadImageHandler = async ({ websiteId, imageKey, imageFile }) => {
  const response = await WebsitesService.uploadImage(
    websiteId,
    imageKey,
    imageFile
  );
  return response;
};

const uploadMultimediaHandler = async ({
  name,
  websiteId,
  section = 'ASSOCIATIONS',
  imageFile,
  mediaType = 'company',
}) => {
  const response = await WebsitesService.uploadMultimedia(
    name,
    websiteId,
    section,
    imageFile,
    mediaType
  );
  return response;
};

const deleteImageHandler = async ({ websiteId, requestBody }) => {
  const response = await WebsitesService.deleteImage(websiteId, requestBody);
  return response;
};

export const fetchFavoriteSearches = handler(
  'fetchFavoriteSearches',
  fetchFavoriteSearchesHandler
);
export const fetchWebsiteData = handler(
  'fetchWebsiteData',
  fetchWebsiteDataHandler
);
export const fetchWebsites = handler(
  'fetchWebsites',
  WebsitesService.getWebsites
);
export const fetchWebsiteAssociations = handler(
  'fetchWebsiteAssociations',
  WebsitesService.getWebsiteAssociations
);
export const createWebsite = handler(
  'createWebsite',
  WebsitesService.createWebsite
);
export const deleteWebsite = handler(
  'deleteWebsite',
  WebsitesService.deleteWebsite
);
export const updateWebsite = handler('updateWebsite', updateWebsiteHandler);
export const fetchTemplates = handler(
  'fetchTemplates',
  TemplatesService.getTemplates
);
export const uploadImage = handler('uploadImage', uploadImageHandler);
export const uploadMultimedia = handler(
  'uploadMultimedia',
  uploadMultimediaHandler
);
export const deleteImage = handler('deleteImage', deleteImageHandler);
export const getLocationsQuickSearch = handler(
  'getLocationsQuickSearch',
  LocationsService.getLocationsQuickSearch
);
export const getLocationsObjList = handler(
  'getLocationsObjList',
  LocationsService.getLocationsObjList
);
export const getLocationsStates = handler(
  'getLocationsStates',
  LocationsService.getLocationsStates
);
export const fetchDefaultValues = handler(
  'fetchDefaultValues',
  PropertiesService.getDefaultValues
);
export const publishWebsite = handler(
  'publishWebsite',
  WebsitesService.publishWebsite
);
export const setAsMainWebsite = handler(
  'setAsMainWebsite',
  WebsitesService.setAsMainWebsite
);
export const saveAliasWebsite = handler(
  'saveAliasWebsite',
  saveAliasWebsiteHandler
);
export const removeAliasWebsite = handler(
  'removeAliasWebsite',
  removeAliasWebsiteHandler
);
export const getAllowedTagGroups = handler(
  'getAllowedTagGroups',
  PropertiesService.getAllowedTagGroups
);
export const getPropertyAttributes = handler(
  'getPropertyAttributes',
  PropertiesService.getPropertyAttributes
);
export const fetchIntercomData = handler(
  'fetchIntercomData',
  WebsitesService.getIntercomData
);
export const putUserModals = handler(
  'putUserModals',
  UserService.putUserModals
);
export const putUpgradePending = handler(
  'putUpgradePending',
  CompanyService.putUpgradePending
);
export const fetchPropertyTypes = handler(
  'fetchPropertyTypes',
  PropertyConfiguratorService.fetchPropertyTypes
);
