import { createSlice } from '@reduxjs/toolkit';
import uniqueId from 'lodash/uniqueId';

const initialState = {
  colaToast: [],
};

export const ToastSlice = createSlice({
  name: 'Toast',
  initialState,
  reducers: {
    showToast(state, action) {
      state.colaToast = [
        ...state.colaToast,
        { id: uniqueId('toast_'), ...action.payload },
      ];
    },
    showUniqueToast(state, action) {
      const toastExists = state.colaToast.some(
        (toast) => toast.text === action.payload.text
      );
      if (!toastExists) {
        state.colaToast = [
          ...state.colaToast,
          { id: state.colaToast.length, ...action.payload },
        ];
      } else {
        state.colaToast = [{ id: 0, ...action.payload }];
      }
    },
    closeToast(state, action) {
      const nuevaCola = state.colaToast.filter(
        (cola) => cola.id !== action.payload
      );
      state.colaToast = nuevaCola;
    },
  },
});

export const { showToast, closeToast, editToast, showUniqueToast } =
  ToastSlice.actions;

export default ToastSlice.reducer;
