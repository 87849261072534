// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"QID3sxqXfld61IpeZ7fIr"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a5c62a4ce9c6f62a913ce7bfffc59b12@o67995.ingest.us.sentry.io/4506162525110272',

    release: process.env.REPO_BRANCH,
    dist: process.env.REPO_COMMIT,

    // Add optional integrations for additional features
    integrations: [Sentry.replayIntegration()],

    tracesSampleRate: 0.1, // Reduce el porcentaje de transacciones registradas
    replaysSessionSampleRate: 0.05, // Reduce los replays
    replaysOnErrorSampleRate: 0.2, // Captura solo algunos replays en errores

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    beforeSend(event) {
      if (!event.exception) return null;

      const errorType = event.exception.values?.[0]?.type;
      const errorMessage = event.exception.values?.[0]?.value;

      if (
        errorType === 'TypeError' ||
        (errorMessage && errorMessage.includes('Cannot read properties'))
      ) {
        return event;
      }

      return null;
    },
  });
}
