import ApiService from '@/services/ApiService';

class LocationsService {
  static async getLocationsQuickSearch(query) {
    const composedURL = `/api3/locations/locations_quicksearch?q=${encodeURIComponent(
      query
    )}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getLocationsObjList(data) {
    const composedURL = `/api3/locations/locations_get_obj_list?data=${encodeURIComponent(
      JSON.stringify(data)
    )}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getLocationsStates(companyCountry = true) {
    const composedURL = `/api3/locations/states?company_country=${companyCountry}`;
    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default LocationsService;
