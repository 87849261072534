import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { deleteFavSearch } from '../favorites/services';
import {
  createWebsite,
  deleteWebsite,
  fetchDefaultValues,
  fetchFavoriteSearches,
  fetchTemplates,
  fetchWebsiteData,
  fetchWebsites,
  getAllowedTagGroups,
  getLocationsObjList,
  getLocationsQuickSearch,
  getLocationsStates,
  getPropertyAttributes,
  publishWebsite,
  putUserModals,
  removeAliasWebsite,
  saveAliasWebsite,
  setAsMainWebsite,
  uploadMultimedia,
  fetchPropertyTypes,
} from './services';
import { normalizeWebsiteData } from './utils';

export const TemplateConfigSlice = createSlice({
  name: 'template-config-data',
  initialState,
  reducers: {
    setInitialState(state, action) {
      return { ...state, ...action.payload };
    },
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setFavicon(state, action) {
      state.favicon = action.payload;
    },
    setField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    setArrayField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = [...value];
    },
    setInformation(state, action) {
      const key = Object.keys(action.payload)[0];
      state[key] = action.payload[key];
    },
    setSocialNetworkLink(state, action) {
      state.socialNetworkLinks[action.payload.network] = action.payload.link;
    },
    setBackgroundImages(state, action) {
      const { id, url } = action.payload;
      state.backgroundImages[id] = url;
    },
    setBackgroundImageAsNone(state, action) {
      const { id } = action.payload;
      state.backgroundImages[id] = null;
    },
    setSectionImages(state, action) {
      const { id, url } = action.payload;
      state.sectionImages[id] = url;
    },
    setSectionImageAsNone(state, action) {
      const { id } = action.payload;
      state.sectionImages[id] = null;
    },
    setBooleanField(state, action) {
      const field = action.payload;
      state[field] = !state[field];
    },
    setModalInfo: (state, action) => {
      const { modalId, info, enabled } = action.payload;
      if (!state.modalIntegrations[modalId]) {
        state.modalIntegrations[modalId] = {
          info: '',
          enabled: false,
        };
      }
      state.modalIntegrations[modalId].info = info;
      state.modalIntegrations[modalId].enabled = enabled;
    },
    setGoogleTranslatePluggin: (state, action) => {
      const { enabled, langsSelected, widgetPosition } = action.payload;
      if (enabled !== undefined) state.googleTranslatePluggin.enabled = enabled;
      if (langsSelected !== undefined)
        state.googleTranslatePluggin.langsSelected = langsSelected;
      if (widgetPosition !== undefined)
        state.googleTranslatePluggin.widgetPosition = widgetPosition;
    },
    setShowAssociations: (state, action) => {
      const association = action.payload;
      if (association) {
        const index = state.showAssociations.info.indexOf(association);
        if (index === -1) {
          state.showAssociations.info.push(association);
        } else {
          state.showAssociations.info.splice(index, 1);
        }
      }
    },
    addAssociation(state, action) {
      const { name, file } = action.payload;
      if (!state.associations['00']) {
        state.associations['00'] = [];
      }
      state.associations['00'].push({ name, file });
    },
    setPrivacyPolicy: (state, action) => {
      const { field, value } = action.payload;
      state.privacyPolicy[field] = value;
    },
    updateSection(state, action) {
      const { sectionId, updates } = action.payload;

      if (state.sectionsGlobal[sectionId]) {
        state.sectionsGlobal[sectionId] = {
          ...state.sectionsGlobal[sectionId],
          ...updates,
        };
      }
    },
    addTagToGroup(state, action) {
      const { groupId, tag } = action.payload;
      if (!state.tagGroups) {
        state.tagGroups = {};
      }
      if (state.tagGroups[groupId]) {
        state.tagGroups[groupId].push(tag);
      } else {
        state.tagGroups[groupId] = [tag];
      }
    },
    removeTagFromGroup(state, action) {
      const { groupId, tagId } = action.payload;
      if (state.tagGroups[groupId]) {
        state.tagGroups[groupId] = state.tagGroups[groupId].filter(
          (tag) => tag.id !== tagId
        );
      }
    },
    addAttributeToGroup(state, action) {
      const { propertyType, attribute } = action.payload;
      if (state.attributes[propertyType].length < 6) {
        state.attributes[propertyType].push({
          type: attribute.type,
          attr: attribute.attr,
          name: attribute.name,
        });
      }
    },
    removeAttributeFromGroup(state, action) {
      const { propertyType, attr } = action.payload;
      state.attributes[propertyType] = state.attributes[propertyType].filter(
        (attribute) => attribute.attr !== attr
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebsiteData.pending, (state) => {
        state.loadingFetchWebsiteData = true;
      })
      .addCase(fetchWebsiteData.fulfilled, (state, action) => {
        const data = action.payload;
        const normalizedData = normalizeWebsiteData(data);
        state.error = null;
        state.loadingFetchWebsiteData = false;

        Object.assign(state, normalizedData);
      })
      .addCase(fetchWebsiteData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchWebsiteData = false;
      })
      .addCase(createWebsite.pending, (state) => {
        state.loadingCreateWebsite = true;
      })
      .addCase(createWebsite.fulfilled, (state, action) => {
        state.webId = action.payload.website_id;
        state.error = null;
        state.loadingCreateWebsite = false;
      })
      .addCase(createWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingCreateWebsite = false;
      })
      .addCase(deleteWebsite.pending, (state) => {
        state.loadingDeleteWebsite = true;
      })
      .addCase(deleteWebsite.fulfilled, (state, action) => {
        state.error = null;
        state.websites = state.websites.filter(
          (website) => website.id !== action.payload.id
        );
        state.loadingDeleteWebsite = false;
      })
      .addCase(deleteWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingDeleteWebsite = false;
      })
      .addCase(fetchWebsites.pending, (state) => {
        state.loadingFetchWebsites = true;
      })
      .addCase(fetchWebsites.fulfilled, (state, action) => {
        state.websites = action.payload.websites;
        state.websitesCanCreate = action.payload.websites_can_create;
        state.error = null;
        state.loadingFetchWebsites = false;
      })
      .addCase(fetchWebsites.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchWebsites = false;
      })
      .addCase(fetchTemplates.pending, (state) => {
        state.loadingFetchTemplates = true;
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.templatesData = action.payload;
        state.hasTemplatePremium = action.payload.has_template_premium;
        state.showPremiumBanner = action.payload.show_upgrade_website;
        state.planUpgradePending = action.payload.is_pending_upgrade;
        state.loadingFetchTemplates = false;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchTemplates = false;
      })
      .addCase(uploadMultimedia.pending, (state) => {
        state.loadingUploadMultimedia = true;
      })
      .addCase(uploadMultimedia.fulfilled, (state) => {
        state.loadingUploadMultimedia = false;
        state.error = null;
      })
      .addCase(uploadMultimedia.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingUploadMultimedia = false;
      })
      .addCase(getLocationsQuickSearch.pending, (state) => {
        state.loadingGetLocationsQuickSearch = true;
      })
      .addCase(getLocationsQuickSearch.fulfilled, (state, action) => {
        state.filteredLocations = action.payload;
        state.loadingGetLocationsQuickSearch = false;
      })
      .addCase(getLocationsQuickSearch.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetLocationsQuickSearch = false;
      })
      .addCase(getLocationsObjList.pending, (state) => {
        state.loadingGetLocationsObjList = true;
      })
      .addCase(getLocationsObjList.fulfilled, (state, action) => {
        state.locationsObjList = action.payload;
        state.loadingGetLocationsObjList = false;
      })
      .addCase(getLocationsObjList.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetLocationsObjList = false;
      })
      .addCase(getLocationsStates.pending, (state) => {
        state.loadingGetLocationsStates = true;
      })
      .addCase(getLocationsStates.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.loadingGetLocationsStates = false;
      })
      .addCase(getLocationsStates.rejected, (state, action) => {
        state.errorLocationsStates = action.error.message;
        state.loadingGetLocationsStates = false;
      })
      .addCase(fetchPropertyTypes.pending, (state) => {
        state.loadingFetchPropertyTypes = true;
      })
      .addCase(fetchPropertyTypes.fulfilled, (state, action) => {
        state.propertyTypes = action.payload;
        state.loadingFetchPropertyTypes = false;
      })
      .addCase(fetchPropertyTypes.rejected, (state, action) => {
        state.errorFetchPropertyTypes = action.error.message;
        state.loadingFetchPropertyTypes = false;
      })
      .addCase(fetchDefaultValues.pending, (state) => {
        state.loadingFetchDefaultValues = true;
      })
      .addCase(fetchDefaultValues.fulfilled, (state, action) => {
        state.defaultValues = action.payload;
        state.loadingFetchDefaultValues = false;
      })
      .addCase(fetchDefaultValues.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchDefaultValues = false;
      })
      .addCase(publishWebsite.pending, (state) => {
        state.loadingPublishWebsite = true;
      })
      .addCase(publishWebsite.fulfilled, (state) => {
        state.loadingPublishWebsite = false;
        state.error = null;
      })
      .addCase(publishWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingPublishWebsite = false;
      })
      .addCase(setAsMainWebsite.pending, (state) => {
        state.loadingSetAsMainWebsite = true;
      })
      .addCase(setAsMainWebsite.fulfilled, (state) => {
        state.error = null;
        state.loadingSetAsMainWebsite = false;
      })
      .addCase(setAsMainWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingSetAsMainWebsite = false;
      })
      .addCase(saveAliasWebsite.pending, (state) => {
        state.loadingSaveAliasWebsite = true;
      })
      .addCase(saveAliasWebsite.fulfilled, (state, action) => {
        const { aliasText, objectId } = action.payload;
        state.aliasDomains.push({ alias: aliasText, id: objectId });
        state.loadingSaveAliasWebsite = false;
        state.error = null;
      })
      .addCase(saveAliasWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingSaveAliasWebsite = false;
      })
      .addCase(removeAliasWebsite.pending, (state) => {
        state.loadingRemoveAliasWebsite = true;
      })
      .addCase(removeAliasWebsite.fulfilled, (state, action) => {
        state.aliasDomains = state.aliasDomains.filter(
          (alias) => alias.id !== action.payload.aliasId
        );
        state.loadingRemoveAliasWebsite = false;
        state.error = null;
      })
      .addCase(removeAliasWebsite.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingRemoveAliasWebsite = false;
      })
      .addCase(fetchFavoriteSearches.pending, (state) => {
        state.loadingFetchFavoriteSearches = true;
      })
      .addCase(fetchFavoriteSearches.fulfilled, (state, action) => {
        state.favoriteSearches = action.payload;
        state.loadingFetchFavoriteSearches = false;
        state.error = null;
      })
      .addCase(fetchFavoriteSearches.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingFetchFavoriteSearches = false;
      })
      .addCase(getAllowedTagGroups.pending, (state) => {
        state.loadingGetAllowedTagGroups = true;
      })
      .addCase(getAllowedTagGroups.fulfilled, (state, action) => {
        state.allowedTagGroups = action.payload;
        state.loadingGetAllowedTagGroups = false;
      })
      .addCase(getAllowedTagGroups.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetAllowedTagGroups = false;
      })
      .addCase(getPropertyAttributes.pending, (state) => {
        state.loadingGetPropertyAttributes = true;
      })
      .addCase(getPropertyAttributes.fulfilled, (state, action) => {
        state.propertyAttributes = action.payload;
        state.loadingGetPropertyAttributes = false;
      })
      .addCase(getPropertyAttributes.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingGetPropertyAttributes = false;
      })
      .addCase(putUserModals.pending, (state) => {
        state.loadingPutUserModals = true;
      })
      .addCase(putUserModals.fulfilled, (state, action) => {
        state.userModals = action.payload;
        state.loadingPutUserModals = false;
        state.error = null;
      })
      .addCase(putUserModals.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingPutUserModals = false;
      })
      .addCase(deleteFavSearch.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.favoriteSearches = state.favoriteSearches.filter(
          (search) => search.id !== id
        );

        // TODO Backend
        for (let i = 1; i <= 5; i++) {
          const presetSearchKey = `preset_search${i}`;
          if (
            state.sectionsGlobal[presetSearchKey] &&
            Array.isArray(state.sectionsGlobal[presetSearchKey].search_id)
          ) {
            state.sectionsGlobal[presetSearchKey].search_id =
              state.sectionsGlobal[presetSearchKey].search_id.filter(
                (searchId) => searchId !== id
              );
          }
        }
      });
  },
});

export const {
  setInitialState,
  setLogo,
  setFavicon,
  setField,
  setArrayField,
  setInformation,
  setSocialNetworkLink,
  setBackgroundImages,
  setBackgroundImageAsNone,
  setSectionImages,
  setSectionImageAsNone,
  setBooleanField,
  setModalInfo,
  setGoogleTranslatePluggin,
  setShowAssociations,
  addAssociation,
  setPrivacyPolicy,
  updateSection,
  addTagToGroup,
  removeTagFromGroup,
  addAttributeToGroup,
  removeAttributeFromGroup,
} = TemplateConfigSlice.actions;

export const selectTemplateConfig = (state) => state.TemplateConfigSlice;

export default TemplateConfigSlice.reducer;
