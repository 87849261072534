import { convert } from 'html-to-text';

function convertHtmlToText(html) {
  const options = {
    wordwrap: false,
    selectors: [
      { selector: 'img', format: 'skip' },
      {
        selector: 'p',
        options: { leadingLineBreaks: 1, trailingLineBreaks: 1 },
      },
    ],
  };

  const doc = new DOMParser().parseFromString(html, 'text/html');

  const updatedHtml = doc.body.innerHTML;
  const text = convert(updatedHtml, options);

  return text;
}

export default convertHtmlToText;
