/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-rest-params */
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import theme from '@oneloop/theme';
import { AppWrapper } from '../context/AppContext';
import store from '@/store/store';
import translations from '@/translations/_app.trans.json';
import Trans from '@/components/shared/Trans';
import DictionaryNamePage from '@/components/shared/DictionaryNamePage';
import '../styles/globals.scss';
import ErrorBoundary from '@/components/ErrorBudanry';

const SettingsReactLoader = dynamic(() =>
  import('@/components/SettingsReactLoader/SettingsReactLoader')
);
const CustomModalsWrapped = dynamic(() =>
  import('@/components/CustomModalsWrapped/CustomModalsWrapped')
);
const Layout = dynamic(() => import('@/components/Layout/Layout'), {
  ssr: false,
});

function AppHead({ pathname }) {
  return (
    <Head>
      <title>
        {DictionaryNamePage[pathname] &&
          Trans(translations, DictionaryNamePage[pathname])}
        {' | Tokko Broker'}
      </title>
      <meta name="description" content="Tokko Broker" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
    </Head>
  );
}

const PublicRoute = React.memo(({ Component, pageProps }) => (
  <ErrorBoundary>
    <Component {...pageProps} />
  </ErrorBoundary>
));

function ProtectedRoute({
  Component,
  pageProps,
  isEnabledToken,
  setEnabledToken,
}) {
  const isLayoutRemoved = Component.layoutRemoved;

  return isEnabledToken ? (
    <ErrorBoundary>
      <CustomModalsWrapped>
        {isLayoutRemoved ? (
          <Component {...pageProps} />
        ) : (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        )}
      </CustomModalsWrapped>
    </ErrorBoundary>
  ) : (
    <SettingsReactLoader setEnabledToken={setEnabledToken} />
  );
}

function MyApp({ Component, pageProps }) {
  const [isEnabledToken, setEnabledToken] = useState(false);
  const router = useRouter();
  const isPublicRoute = Component.public;

  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <Provider store={store}>
          {/* GTM Script */}
          <Script
            id="google-tag-manager"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5WVHQNX');
          `,
            }}
          />

          <AppHead pathname={router.pathname} />
          {isPublicRoute ? (
            <PublicRoute Component={Component} pageProps={pageProps} />
          ) : (
            <ProtectedRoute
              Component={Component}
              pageProps={pageProps}
              router={router}
              isEnabledToken={isEnabledToken}
              setEnabledToken={setEnabledToken}
            />
          )}
        </Provider>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default MyApp;
